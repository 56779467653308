import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useKeyPress } from '../hooks/useKeyPress'

export const OfferFormContext = createContext({
  showForm: false,
  onOpenForm: () => {},
  onCloseForm: () => {},
  canGuaranteeDate: new Date(),
  resetCanGurantee: () => {},
})

export interface OfferFormContextProps {
  showForm: boolean
  onOpenForm: () => void
  onCloseForm: () => void
  canGuaranteeDate: Date
  resetCanGurantee: () => void
}

export interface OfferFormProviderProps {
  children: ReactNode
}

export const OfferFormProvider = ({ children }: OfferFormProviderProps) => {
  const [showForm, setShowForm] = useState(false)
  const [canGuaranteeDate, setCanGuaranteeDate] = useState<Date>(new Date())
  const escapePress = useKeyPress('Escape')

  const onOpenForm = () => setShowForm(true)
  const onCloseForm = () => setShowForm(false)

  useEffect(() => {
    if (escapePress) {
      onCloseForm()
    }

    return () => {}
  }, [escapePress])

  return (
    <OfferFormContext.Provider
      value={{
        showForm,
        onOpenForm,
        onCloseForm,
        canGuaranteeDate,
        resetCanGurantee: () => setCanGuaranteeDate(new Date()),
      }}
    >
      {children}
    </OfferFormContext.Provider>
  )
}

export const useOfferFormContext = () => {
  const context = useContext(OfferFormContext)

  if (context === undefined) {
    throw new Error(
      'useOfferFormContext must be used within a OfferFormProvider',
    )
  }

  return context
}
