import React from 'react'

import { CalculatorProvider } from './src/context/CalculatorContext'
import { OfferFormProvider } from './src/context/OfferFormContext'

/* eslint-disable global-require */
declare const fbq: any

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`)
  }
}

export const onRouteUpdate = () => {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView')
  }
}

export const wrapRootElement = ({ element }) => (
  <CalculatorProvider>
    <OfferFormProvider>{element}</OfferFormProvider>
  </CalculatorProvider>
)
