import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

export const CalculatorContext = createContext({
  requestedAmount: 1000000,
  setRequestedAmount: (amount: number) => {},
  minimumAmount: 100000,
  maximumAmount: 5000000,
  stepAmount: 100000,
  // requested monthly payment
  requestedMonthlyPayment: 10000,
  setRequestedMonthlyPayment: (amount: number) => {},
  minimumMonthlyPayment: 7800,
  maximumMonthlyPayment: 500000,
  stepMonthlyPayment: 100,
})

export interface CalculatorProviderProps {
  children: ReactNode
}

export const CalculatorProvider = ({ children }: CalculatorProviderProps) => {
  const [requestedAmount, setRequestedAmount] = useState(1000000)
  const minimumAmount = 100000
  const maximumAmount = 5000000
  const stepAmount = 100000
  const [requestedMonthlyPayment, setRequestedMonthlyPayment] = useState(10000)
  const [minimumMonthlyPayment, setMinimumMonthlyPayment] = useState(7800)
  const [maximumMonthlyPayment, setMaximumMonthlyPayment] = useState(
    requestedAmount / 2,
  )
  const stepMonthlyPayment = 100

  useEffect(() => {
    const newMinimumMonthlyPayment =
      Math.ceil(requestedAmount / 125.4 / 10) * 10
    const newMaximumMonthlyPayment = Math.ceil(requestedAmount / 2)

    setMinimumMonthlyPayment(newMinimumMonthlyPayment) // +value / 136.9 / 10) * 10
    setMaximumMonthlyPayment(newMaximumMonthlyPayment)

    if (requestedMonthlyPayment < newMinimumMonthlyPayment) {
      setRequestedMonthlyPayment(newMinimumMonthlyPayment)
    }

    if (requestedMonthlyPayment > newMaximumMonthlyPayment) {
      setRequestedMonthlyPayment(newMaximumMonthlyPayment)
    }
  }, [requestedAmount, requestedMonthlyPayment])

  return (
    <CalculatorContext.Provider
      value={{
        requestedAmount,
        setRequestedAmount,
        minimumAmount,
        maximumAmount,
        stepAmount,
        requestedMonthlyPayment,
        setRequestedMonthlyPayment,
        minimumMonthlyPayment,
        maximumMonthlyPayment,
        stepMonthlyPayment,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  )
}

export const useCalculatorContext = () => {
  const context = useContext(CalculatorContext)

  if (context === undefined) {
    throw new Error(
      'useCalculatorContext must be used within a CalculatorProvider',
    )
  }

  return context
}
